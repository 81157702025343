import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsistema) {
    let apiCalls = []
    const apiFilterTDatoSistema = new APIFilter()
    apiFilterTDatoSistema
      .addExact('idsistema', idsistema)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectTdatoSistema', method: 'tdatoSistema.select', params: { filter: apiFilterTDatoSistema } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('iddato_sistema', id)
    const resp = await Vue.$api.call('datoSistema.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectTdatoSistema (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idtdato_sistema', id)
    const resp = await Vue.$api.call('tdatoSistema.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
