import { toDate } from '@/utils/date'
import filters from '@/utils/filters'
import { TDATO_TDATO_SISTEMA } from '@/utils/consts'

export default {
  async insert (Vue, formData, idsistema) {
    let valor = formData.valor
    if (TDATO_TDATO_SISTEMA.tiposDate.includes(formData.tipo)) {
      valor = filters.shortDate(toDate(valor))
    }
    const resp = await Vue.$api.call(
      'datoSistema.insert',
      {
        values: {
          idtdato_sistema: formData.tdato_sistema.idtdato_sistema,
          valor: valor,
          idsistema: idsistema,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
