<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <dato-sistema-form
          :idsistema="routeParams.idsistema"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import DatoSistemaForm from '../components/DatoSistemaForm'
import Data from './DatoSistemaAddData'

export default {
  components: {
    DatoSistemaForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir datosistema'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const _ = await Data.insert(this, this.formData, this.routeParams.idsistema)
      this.$appRouter.replace({
        name: 'sistemas__dato-sistema-list',
        params: {
          idsistema: this.routeParams.idsistema,
        },
      })
    },
  },
}
</script>
